
import { createApp } from 'vue'
import TourList from './components/TourList.vue'
import TheText from './components/TheText.vue'












createApp(TourList).mount('#TourList')
createApp(TheText).mount('#TheText')
