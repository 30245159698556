<template>

    <p v-if="texts.length != 0"> {{ texts.story.content.body[0].banner_text }} </p>      
</template>

<script>

export default {
   
   data() {
        return {
            dataTexts: null
        }},
    mounted() {
        fetch('https://api.storyblok.com/v2/cdn/stories/home?cv=1645786266&token=USDwrrpw7P58WCcmZRLJSAtt&version=published', {
            headers: { 'Content-type': 'application/json'},
        }).then(res=>res.json()).then((response) => {
            this.dataTexts = response;
        }).catch( (error) => {
            this.dataTexts = error;
        });
    },
    computed: {
   texts(){
       if (this.dataTexts) return this.dataTexts
       return []
    }
 }
 
 
}


</script>

<style scoped>
p {
    color:white;
}
</style>
