<template>

    <div v-for="tour in toursCurrent" :key="tour.title"  class="col-sm-6 col-md-3 box" :style="{ 'background-image': 'url(' + tour.main_photo + ')' }"> 
        <a class="tourlink" v-bind:href="tour.url + '?utm_source=francienakole'" target="_blank"></a>
    
        <div class="undertitle"> <h2>{{ tour.title }} <span class="days" v-for="date in tour.dates.slice(0, 1)" :key="date" >{{ date.day_count}} dní</span></h2>

            <div class="days">
            
        </div>

        </div>
        
        
        <div class="undertitle2"> 
            <ul v-if="tour.dates.length <= 2" class="datum" >
                <li v-for="date in tour.dates.slice(0, 2)" :key="date">{{ dateTimeShort(date.date_from) }} - {{ dateTime(date.date_to) }}</li>
                
            </ul>
            <ul v-else class="datum"  >
                <li v-for="date in tour.dates.slice(0, 1)" :key="date">{{ dateTimeShort(date.date_from) }} - {{ dateTime(date.date_to) }}</li>
                <li>+ {{ tour.dates.length-1 }} další termíny</li>
                
            </ul>

            <div class="cena">
                <button  v-for="date in tour.dates.slice(0, 1)" :key="date" class="btn btn-outline-light">od: {{ formatPrice(date.price_from) }}</button>
            </div>
        </div>     
    </div>        
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      tours: [],
    };
  },
  mounted() {
    this.fetchTours();
  },
  methods: {
    async fetchTours() {
      try {
        const response = await fetch('https://zanet.adventura.cz/api/v1/francie-na-kole/', {
          headers: { 'Content-type': 'application/json' },
        });
        this.tours = await response.json();
      } catch (error) {
        this.tours = error;
      }
    },
    dateTime(value) {
      return moment(value).format('DD. MM. YYYY');
    },
    dateTimeShort(value) {
      return moment(value).format('DD. MM. ');
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        maximumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
  computed: {
    toursCurrent: function () {
      return this.tours.filter(function (tour) {
        return tour.dates.length > 0;
      });
    },
  },
};
</script>


<style scoped>
a {
    color: #ffffff;
    text-decoration: none;
}

.row{
    justify-content: center;
}

.tourlink {
    position: absolute;
    height: 365px;
    max-height: 365px;
    width: 300px;
}

.box {
    width: 300px;
    border-radius: 10px;
    transition: 0.2s;
    color: white;
    height: 365px;
    max-height: 365px;
    margin: 10px;
    background-size: cover;
    box-shadow: 0 0 8px 0 #00000033;
    padding: 0;
}

.box:hover {
    transform: scale(1.05);
    box-shadow: 0 0 8px 0 #00000073;

}
.days {
   margin-top: -20px;
    float: right;
    position: absolute;
    top: 74px;
    line-height: 1.2em;
    font-size: 12px;
    right: 0;
}

.btn {
    background-image: linear-gradient(to right, #f4811e 0%, #fdbb2d 51%, #f4811e 100%);
    transition: 0.5s;
    border: none;
    background-size: 200% auto;
    font-weight: bold;
}

h2 {
    font-size: 1.2em;
    float: left;
    position: relative;
    padding-right: 50px;
    padding-top: 7px;
}
.undertitle {
    background: #00000087;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 5px 10px 5px 10px;
    min-height: 85px;
}

.box:hover ~ .undertitle {
    background: #00000081;
}

.undertitle2 {
    
    background: #ffffff;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px 10px 5px 10px;
    min-height: 60px;
    margin-top: 220px;
    
}

.cena {
  float: right;
  bottom: 0;
  margin: 0 auto;

}

.datum {
    color: #606060;
    font-size: 14px;
    float: left;
    list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
</style>
